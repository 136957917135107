import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Sam = () => {
	const { sam: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `Dr Sam Rao`]}
				description="About Dr Sam Rao"
			/>
			<ProfilePage
				title="Sam Rao - Endoscopist & Gastroenterologist"
				name="Dr Sam Rao"
				img={pic.childImageSharp.fixed}
				quote=""
				specialty="Therapeutic and general endoscopy, Pancreaticobiliary disease"
				education={[
					"Postgraduate GI Training: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Royal Perth Hospital, Perth, Western Australia",
					"Fellowship: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Fellowship: Vancouver General Hospiral, Vancouver Canada",
					"Fellowship: St Paul's Hospital, Canada",
				]}
				description={[
					"Dr Sam Rao is a gastroenterologist with special interest in therapeutic endoscopy and pancreaticobiliary disease. He completed his specialty training in Perth followed by advanced endoscopy fellowships at Sir Charles Gairdner Hospital, Perth and Vancouver, Canada (Vancouver General & St. Paul’s Hospitals).",
					"In addition to general endoscopic procedure, his expertise includes advanced endoscopic procedures such as endoscopic ultrasound, ERCP, management of large colon polyps, management of Barrett’s oesophagus and general endoscopy.",
					"Dr Rao also has appointments at Sir Charles Gairdner and Osborne Park Hospitals. He is a an active member in the Australian and New Zealand Endoscopy Leaders Forum (ANZELF).",
				]}
				honours={[
					"",
				]}
				background="Dr Rao completed his gastroenterology training in Perth. He subsequently undertook fellowship training in Canada in interventional endoscopy before returning to Perth and being appointed as a Consultant Gastroenterologist at Sir Charles Gairdner Hospital."
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Private appointment - SJOG, Subiaco",
					"Public appointment - Sir Charles Gairdner Hospital, Perth",
					"Public appointment - Osborne Park Hospital (ASI Open Access Endoscopy)",
				]}
				memberships={[
					"Australian and New Zealand Endoscopy Leaders Forum (ANZELF)",
					"Gastroenterological Society of Australia (GESA)",
					"Australian Health Practitioner Regulation Agency (AHPRA)",
					"The Royal Australasian College of Physicians (RACP)",
					"Australian Medical Association (AMA)",
				]}
				personal={[
					"My hobbies include hiking, photography and cricket.",
				]}
				publications={[
					{
						publicationTitle: "Diagnostic utility of α-inhibin immunohistochemistry in the biopsy confirmation of pancreatic serous cystadenoma",
						authors: "Mike Steel, Samarth Rao, Huimin Yang, Fergal Donnellan, David Schaeffer ",
					},
					{
						publicationTitle: "Management of Chronic Hepatitis B in Pregnancy - Tenofovir or Lamivudine?",
						authors: "S Rao, N Kontorinis, L Tarquinio, J Kong, J Flexman, W Cheng",
					},
					{
						publicationTitle: "Development of Autoimmune Type I Diabetes Mellitus during treatment with Peginterferon and Ribavirin for chronic hepatitis C- a tertiary centre experience",
						authors: "S Rao, L Tarquinio, N Kontorinis, M Lucas, S Nazareth, R Tuma, W Cheng",
					},
					{
						publicationTitle: "Management of chronic hepatitis B with Tenofovir Disoproxil Fumarate is not associated with significant renal toxicity",
						authors: "S Rao, N Kontorinis, L Tarquinio, J Kong, M Thomas, W Cheng",
					},
					{
						publicationTitle: "High Yield for Advanced Colorectal Neoplasia (Carcinoma and Advanced Adenoma) Detection with Community Based Faecal Immunochemical Testing",
						authors: "S Rao, G Forbes et al",
					},
					{
						publicationTitle: "Direct Acting Antiviral Drugs in Chronic Hepatitis C and Renal Toxicity",
						authors: "S Rao, N Kontorinis, L Tarquinio, J Kong, L Mollison, G MacQuillan, L Adams, G Jeffrey, S Galhenage, S Nazareth, L Totten, J Vallve, W Cheng",
					},
					{
						publicationTitle: "Early Experience on Treatment with Direct Acting Antiviral Drugs in Chronic Hepatitis C - interim analysis in Western Australian tertiary centres",
						authors: "S Rao, N Kontorinis, L Tarquinio, J Kong, L Mollison, G MacQuillan, L Adams, G Jeffrey, S Galhenage, S Nazareth, L Totten, J Vallve, W Cheng",
					},
				]}
			/>
		</>
	);
};

export default Sam;
